import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_2ef9732e from 'nuxt_plugin_plugin_2ef9732e' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_97cb5cd4 from 'nuxt_plugin_plugin_97cb5cd4' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_axios_0fbc7344 from 'nuxt_plugin_axios_0fbc7344' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxtplugin2518ae65_a07a8612 from 'nuxt_plugin_nuxtplugin2518ae65_a07a8612' // Source: ./nuxt.plugin.2518ae65.js (mode: 'server')
import nuxt_plugin_vuescrollto_6d1f1e45 from 'nuxt_plugin_vuescrollto_6d1f1e45' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_488f0aec from 'nuxt_plugin_cookieuniversalnuxt_488f0aec' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_5a432912 from 'nuxt_plugin_pluginutils_5a432912' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_753e5eea from 'nuxt_plugin_pluginseo_753e5eea' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_4af1f702 from 'nuxt_plugin_pluginrouting_4af1f702' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_bb6fb8a6 from 'nuxt_plugin_pluginmain_bb6fb8a6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_moduleplugin151696eb_7ae2a867 from 'nuxt_plugin_moduleplugin151696eb_7ae2a867' // Source: ./module.plugin.151696eb.mjs (mode: 'all')
import nuxt_plugin_distplugin5eea0cba_91f01c88 from 'nuxt_plugin_distplugin5eea0cba_91f01c88' // Source: ./dist.plugin.5eea0cba.mjs (mode: 'all')
import nuxt_plugin_image_0a791f82 from 'nuxt_plugin_image_0a791f82' // Source: ./image.js (mode: 'all')
import nuxt_plugin_corepluginfa0c96d2_026927c5 from 'nuxt_plugin_corepluginfa0c96d2_026927c5' // Source: ./core.plugin.fa0c96d2.ts (mode: 'all')
import nuxt_plugin_pluginse2etesting4b5689b7_327f5eee from 'nuxt_plugin_pluginse2etesting4b5689b7_327f5eee' // Source: ./plugins.e2e-testing.4b5689b7.js (mode: 'all')
import nuxt_plugin_deviceplugin_6f42f262 from 'nuxt_plugin_deviceplugin_6f42f262' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_d785b140 from 'nuxt_plugin_workbox_d785b140' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_61ade31a from 'nuxt_plugin_metaplugin_61ade31a' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_585e9832 from 'nuxt_plugin_iconplugin_585e9832' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_tokenexpired_ca1e20ca from 'nuxt_plugin_tokenexpired_ca1e20ca' // Source: ../plugins/token-expired (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_fcPlugin_5a846bc6 from 'nuxt_plugin_fcPlugin_5a846bc6' // Source: ../plugins/fcPlugin (mode: 'all')
import nuxt_plugin_dompurify_9e093c4a from 'nuxt_plugin_dompurify_9e093c4a' // Source: ../plugins/dompurify (mode: 'all')
import nuxt_plugin_storeConfigPlugin_234467e0 from 'nuxt_plugin_storeConfigPlugin_234467e0' // Source: ../plugins/storeConfigPlugin (mode: 'all')
import nuxt_plugin_pricerangeslider_1d09c6ac from 'nuxt_plugin_pricerangeslider_1d09c6ac' // Source: ../plugins/price-range-slider.js (mode: 'client')
import nuxt_plugin_vuetinyslider_67155006 from 'nuxt_plugin_vuetinyslider_67155006' // Source: ../plugins/vue-tiny-slider.js (mode: 'client')
import nuxt_plugin_vuejsontocsv_6bdcf788 from 'nuxt_plugin_vuejsontocsv_6bdcf788' // Source: ../plugins/vue-json-to-csv (mode: 'client')
import nuxt_plugin_meta_f314cbb0 from 'nuxt_plugin_meta_f314cbb0' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Dé kappersleverancier voor exclusieve producten","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"description"},{"hid":"og:title","name":"og:title","property":"og:title","content":"HOME"},{"hid":"og:description","name":"og:description","property":"og:description","content":"Welkom bij TRINITY haircare \n          Dé kappersleverancier\n          Ben je op zoek naar een kappersleverancier om de hoogste kwaliteit exclusieve kappersproducten aan te leveren bij jouw salon? Schakel TRINITY haircare in als de standaard leverancier voor jouw kapsalon en geniet als kapper exclusief van de hoogste kwaliteit haarproducten in jouw salon. Daarnaast reken je"},{"hid":"og:url","name":"og:url","property":"og:url","content":"https:\u002F\u002Fwww.trinity-haircare.nl"},{"hid":"og:type","name":"og:type","property":"og:type","content":"article"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"TRINITY haircare"},{"hid":"og:image","name":"og:image","property":"og:image","content":"https:\u002F\u002Fwww.trinity-haircare.nl\u002Fwp-content\u002Fuploads\u002F2021\u002F01\u002Ftr.svg"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"name":"apple-mobile-web-app-title","content":"Trinity Haircare"},{"name":"robots","content":"noindex, nofollow"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"apple-touch-icon","type":"image\u002Fpng","href":"\u002Fapple-touch-icon.png","sizes":"180x180"}],"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2ef9732e === 'function') {
    await nuxt_plugin_plugin_2ef9732e(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_97cb5cd4 === 'function') {
    await nuxt_plugin_plugin_97cb5cd4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_0fbc7344 === 'function') {
    await nuxt_plugin_axios_0fbc7344(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin2518ae65_a07a8612 === 'function') {
    await nuxt_plugin_nuxtplugin2518ae65_a07a8612(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_6d1f1e45 === 'function') {
    await nuxt_plugin_vuescrollto_6d1f1e45(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_488f0aec === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_488f0aec(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_5a432912 === 'function') {
    await nuxt_plugin_pluginutils_5a432912(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_753e5eea === 'function') {
    await nuxt_plugin_pluginseo_753e5eea(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_4af1f702 === 'function') {
    await nuxt_plugin_pluginrouting_4af1f702(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_bb6fb8a6 === 'function') {
    await nuxt_plugin_pluginmain_bb6fb8a6(app.context, inject)
  }

  if (typeof nuxt_plugin_moduleplugin151696eb_7ae2a867 === 'function') {
    await nuxt_plugin_moduleplugin151696eb_7ae2a867(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin5eea0cba_91f01c88 === 'function') {
    await nuxt_plugin_distplugin5eea0cba_91f01c88(app.context, inject)
  }

  if (typeof nuxt_plugin_image_0a791f82 === 'function') {
    await nuxt_plugin_image_0a791f82(app.context, inject)
  }

  if (typeof nuxt_plugin_corepluginfa0c96d2_026927c5 === 'function') {
    await nuxt_plugin_corepluginfa0c96d2_026927c5(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting4b5689b7_327f5eee === 'function') {
    await nuxt_plugin_pluginse2etesting4b5689b7_327f5eee(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_6f42f262 === 'function') {
    await nuxt_plugin_deviceplugin_6f42f262(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_d785b140 === 'function') {
    await nuxt_plugin_workbox_d785b140(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_61ade31a === 'function') {
    await nuxt_plugin_metaplugin_61ade31a(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_585e9832 === 'function') {
    await nuxt_plugin_iconplugin_585e9832(app.context, inject)
  }

  if (typeof nuxt_plugin_tokenexpired_ca1e20ca === 'function') {
    await nuxt_plugin_tokenexpired_ca1e20ca(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_fcPlugin_5a846bc6 === 'function') {
    await nuxt_plugin_fcPlugin_5a846bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_dompurify_9e093c4a === 'function') {
    await nuxt_plugin_dompurify_9e093c4a(app.context, inject)
  }

  if (typeof nuxt_plugin_storeConfigPlugin_234467e0 === 'function') {
    await nuxt_plugin_storeConfigPlugin_234467e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pricerangeslider_1d09c6ac === 'function') {
    await nuxt_plugin_pricerangeslider_1d09c6ac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetinyslider_67155006 === 'function') {
    await nuxt_plugin_vuetinyslider_67155006(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuejsontocsv_6bdcf788 === 'function') {
    await nuxt_plugin_vuejsontocsv_6bdcf788(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_f314cbb0 === 'function') {
    await nuxt_plugin_meta_f314cbb0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
